<template>
  <Field
    v-model="modelValue"
    :name="props.name"
    :rules="props.rules"
    :label="props.label"
  >
    <label
      :for="props.id"
      class="flex cursor-pointer items-center gap-4 first-letter:text-sm"
      :class="[
        {
          'pointer-events-none opacity-30 grayscale': props.disabled,
        },
      ]"
    >
      <input
        v-bind="$attrs"
        :id="props.id"
        :value="props.value"
        :disabled="props.disabled"
        type="radio"
        :name="props.name"
        class="relative border-2  after:absolute after:left-0 after:top-0 after:size-full after:rounded-full after:border-[3px]  after:bg-transparent  checked:bg-none  "
        :class="[
          {
            'border-primary accent-primary after:border-blue-300 checked:bg-primary hover:border-blue-150 hover:bg-blue-150 hover:checked:border-primary hover:checked:bg-primary': props.variant === 'primary',
            'border-secondary accent-secondary after:border-blue-300 checked:bg-secondary hover:border-blue-150 hover:bg-blue-150 hover:checked:border-primary hover:checked:bg-primary': props.variant === 'secondary',
          },
        ]"
        :style="{ width: '30px', height: '30px' }"
        :checked="isRadioChecked"
        @change="onOptionChange"
      >
      <p v-if="props.label || props.description">
        <span
          v-if="props.label"
          class="block text-primary"
          :class="labelClass"
        >
          {{ props.label }}
        </span>
        <span
          v-if="props.description"
          class="block text-sm text-primary"
        >{{
          props.description
        }}</span>
      </p>
    </label>
  </Field>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { Field } from 'vee-validate'

import type { RadioValue, UiFormFieldRadio } from './UiFormFieldRadio.types'

const props = withDefaults(defineProps<UiFormFieldRadio>(), {
  variant: 'primary',
  disabled: false,
  label: '',
  name: '',
  rules: '',
  additionalMsg: '',
  showValidationMessage: true,
  selectedValue: '',
  description: '',
  labelClass: '',
})

const emit = defineEmits<{
  (e: 'change', value: RadioValue): void
}>()

const modelValue = ref<string | number>(props.selectedValue)

function onOptionChange(event: Event) {
  const value = (event.target as HTMLInputElement).value
  modelValue.value = value
  emit('change', value)
}

const isRadioChecked = computed(() => modelValue.value === props.value)

watch(
  () => props.selectedValue,
  () => (modelValue.value = props.selectedValue),
)
</script>
